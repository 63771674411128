import { useState, useEffect, useMemo, useCallback } from "react";

import fscreen from "fscreen";

import makeStyles from "@material-ui/core/styles/makeStyles";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";

import PageIcon from "@slqweb/icons/main/Page";
import DoublePageIcon from "@slqweb/icons/main/DoublePage";
import FileDownloadIcon from "@slqweb/icons/main/FileDownload";

import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import { GetProgress, Mode } from './Book'
import { event } from '../utils/analytics';

const useStyles = makeStyles(
  ({ palette: { lightBlue }, spacing, breakpoints: { down } }) => ({
    pageInput: {
      width: "4.4rem",
      "& .MuiInputBase-input": {
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: "center",
      },
    },
    active: {
      "&.MuiIconButton-colorInherit": {
        color: lightBlue?.main,
      },
    },
    layout: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "16px",
      position: "relative",
    },
    controls: {
      display: "flex",
    },
    centerControlsLayout: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [down("md")]: {
        position: "unset",
      },
    },
    centerControls: {
      pointerEvents: "all",
      display: "flex",
      alignItems: "baseline",
    },
    main: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: "0 0 100%",
    },
    totalSpan: {
      margin: spacing(0, 1),
    },
    select: {
      margin: spacing(0, 2),
      width: "11.5rem",
    },
  })
);

export const zoomLevels = [
  { value: 1, label: "100%" },
  { value: 1.5, label: "150%" },
  { value: 1.75, label: "175%" },
  { value: 2, label: "200%" },
  { value: 4, label: "400%" },
  { value: 8, label: "800%" },
];

const findNearestZoomIndex = (zoom: number) =>
  zoomLevels.reduce<{ value: number; index: number }>(
    (acc, { value }, index) =>
      Math.abs(value - zoom) < Math.abs(acc.value - zoom)
        ? { value, index }
        : acc,
    { value: 1, index: zoomLevels.findIndex(({ value }) => value === 1) }
  ).index;

export default ({
  loaded,
  pageIndex,
  setPageIndex,
  maxPageIndex,
  length,
  setMode,
  url,
  mode,
  fullscreen,
  setFullscreen,
  zoom: externalZoom,
  setZoom,
  getProgress,
}: {
  loaded: boolean;
  pageIndex: number;
  setPageIndex: (newPage: number) => void;
  maxPageIndex: number;
  length: number;
  setMode: (mode: Mode) => void;
  url: string;
  mode: "single"// | "double";
  fullscreen: boolean;
  setFullscreen: (setState: (oldState: boolean) => boolean) => void;
  zoom: number;
  setZoom: (newZoom: number) => void;
  getProgress: GetProgress;
}) => {
  const {
    pageInput,
    active,
    layout,
    controls,
    centerControlsLayout,
    centerControls,
    main,
    totalSpan,
    select,
  } = useStyles();
  const zoom = useMemo(() => externalZoom, [externalZoom]);
  const [localPageNumber, setLocalPageNumber] = useState<number | null>(pageIndex + 1)

  const handlePageInput = useCallback(() => {
    (document.activeElement as HTMLElement | null)?.blur();

    if (localPageNumber !== null) setPageIndex(localPageNumber - 1)
  }, [localPageNumber, setPageIndex]);

  useEffect(() => {
    setLocalPageNumber(Math.min(pageIndex + 1, length));
  }, [pageIndex, length]);

  return (
    <div className={main}>
      <Container>
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={getProgress()}
        />
        <div className={layout}>
          <div className={ controls }>
            <IconButton
              disabled={!loaded}
              color={mode === "single" ? "inherit" : "secondary"}
              className={active}
              onClick={() => {
                // Re-align pages
                setPageIndex(Math.min(length - 1, pageIndex));
                setMode("single");
              }}
            >
              <PageIcon />
            </IconButton>
            {/*<IconButton
              disabled={!loaded}
              color={mode === "double" ? "inherit" : "secondary"}
              className={active}
              onClick={() => {
                // Re-align pages
                setPageIndex(
                  pageIndex % 2 ? Math.max(pageIndex - 1, 0) : pageIndex
                );
                setMode("double");
              }}
            >
              <DoublePageIcon />
            </IconButton>*/}
          </div>
          <div className={centerControlsLayout}>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handlePageInput();
              }}
              className={centerControls}
            >
              <TextField
                disabled={!loaded}
                color="secondary"
                value={localPageNumber ?? ""}
                inputMode="numeric"
                InputProps={{ inputProps: { min: 1, max: maxPageIndex + 1 }}}
                className={pageInput}
                onChange={(event) => {
                  const num = parseInt(event.target.value)
                  if (!num || (num >= 1 && num <= maxPageIndex + 1)) setLocalPageNumber(num || null)
                }}
                onBlur={handlePageInput}
              />
              <Typography color="secondary" variant="body1">
                <span className={totalSpan}>/</span>
                {length}
              </Typography>
            </form>
          </div>
          <div className={ controls }>
            <IconButton
              color="secondary"
              disabled={
                !loaded ||
                zoom >= zoomLevels[zoomLevels.length - 1].value ||
                zoomLevels.findIndex(({ value }) => value === zoom) ===
                  zoomLevels.length - 1
              }
              onClick={() =>
                setZoom(zoomLevels[findNearestZoomIndex(zoom) + 1].value)
              }
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              color="secondary"
              disabled={
                !loaded ||
                zoom <= zoomLevels[0].value ||
                zoomLevels.findIndex(({ value }) => value === zoom) === 0
              }
              onClick={() =>
                setZoom(zoomLevels[findNearestZoomIndex(zoom) - 1].value)
              }
            >
              <RemoveCircleOutlineOutlinedIcon />
            </IconButton>
            <Hidden xsDown>
              <Select
                disabled={!loaded}
                color="secondary"
                className={select}
                value={zoom}
                onChange={({ target: { value } }) => {
                  if (typeof value === "number") {
                    setZoom(value);
                  }
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                }}
              >
                {[
                  ...zoomLevels,
                  ...(zoomLevels.some(({ value }) => value === zoom)
                    ? []
                    : [
                        {
                          value: zoom,
                          label: `${Math.round(zoom * 100)}%`,
                          hidden: true,
                        },
                      ]),
                ].map(({ value, label, ...props }, key) => (
                  <MenuItem
                    {...{ value, key }}
                    style={{
                      display: "hidden" in props ? "none" : "undefined",
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Hidden>

            <IconButton
              disabled={!loaded}
              color="secondary"
              component="a"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                event('Download', 'flippybook');
              }}
            >
              <FileDownloadIcon />
            </IconButton>

            <IconButton
              color="secondary"
              disabled={!fscreen.fullscreenEnabled || !loaded}
              onClick={() =>
                setFullscreen((oldFullscreen) => !oldFullscreen)
              }
            >
              {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </div>
        </div>
      </Container>
    </div>
  );
};
